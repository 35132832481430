import { useEffect, useState } from "react";
import { useNotifications } from "../../components/Alert/Notification/NotificationContext";
import { useApi } from "../../utils/apiContext";
import { useRoute } from "../../utils/RouteContext";
import { useNavigate, useParams } from "react-router-dom";
import PhoneNumberInput from "./PhoneNumberInput";
import Spinner from '../../components/Spinners/CircleSpinner/CirlceSpinner';
import ChatWindow from "../Dashboard/ChatWindow";
import ButtonWithLoading from "../../components/Buttons/GeneralButton/BaseButton";
import "./NewTaskPage.css";
import Header from "../../components/Header/Header";
import { useTaskCache } from '../Tasks/TaskCacheContext';

const NewTaskPage = ({ baseURL }) => {
    const { createEmptyTask, deleteTask, startTask, fetchTask, updateTask } = useApi();
    const { addNotification } = useNotifications();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { determineRoute, RouteEnum } = useRoute();
    const [outboundPhoneNumber, setOutboundPhoneNumber] = useState("");
    const [newTaskId, setNewTaskId] = useState(null);
    const [isTaskStarting, setIsTaskStarting] = useState(false); // Renamed here
    const [isTaskStarted, setIsTaskStarted] = useState(false);
    const { task_id } = useParams();
    const { taskCache, setTaskCache } = useTaskCache();

    useEffect(() => {
        const initializeTask = async () => {
            setIsLoading(true);
            try {
                if (task_id) {
                    // If task_id exists, fetch the task details
                    let taskDetails;
                    if (taskCache[task_id]) {
                        taskDetails = taskCache[task_id];
                    } else {
                        const data = await fetchTask(null, task_id, null, null);
                        taskDetails = data.tasks[0];
                        setTaskCache(prevCache => ({ ...prevCache, [task_id]: taskDetails }));
                    }
                    setNewTaskId(task_id);
                    const beautifiedPhoneNumber = beautifyPhoneNumber(taskDetails.outbound_phone_number);
                    setOutboundPhoneNumber(beautifiedPhoneNumber);
                } else {
                    // Create a new task if no task_id is provided
                    const data = await createEmptyTask();
                    if (data.error) {
                        if (data.error === "ALREADY_TASK_IN_PROGRESS") {
                            setError("A task is already in progress. Please finish it before starting a new one.");
                        } else {
                            setError("Failed to create a task: " + data.error);
                        }
                    } else if (data.validation_errors) {
                        setError("Failed to create a task");
                    }
                    setNewTaskId(data.task_id);
                }
            } catch (error) {
                setError("Failed to initialize task: " + error.message);
            } finally {
                setIsLoading(false);
            }
        };

        console.log(newTaskId)
        if (!newTaskId) {
            initializeTask();
        }
    }, [task_id, taskCache, setTaskCache, createEmptyTask, fetchTask, newTaskId]);

    const beautifyPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^1?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return phoneNumber;
    };

    useEffect(() => {
        if (isTaskStarted) {
            const route = determineRoute(RouteEnum.DASHBOARD);
            navigate(route);
        }
    }, [isTaskStarted, navigate, determineRoute, RouteEnum]);

    useEffect(() => {
        const updatePhoneNumber = async () => {
            if (newTaskId && outboundPhoneNumber?.replace(/[^\d]/g, "").length === 10) {
                try {
                    let cleanedOutboundPhoneNumber = outboundPhoneNumber.replace(/[^\d]/g, "");
                    cleanedOutboundPhoneNumber = `+1${cleanedOutboundPhoneNumber}`;
                    await updateTask(newTaskId, { outbound_phone_number: cleanedOutboundPhoneNumber });

                    // Update the task in cache as well
                    setTaskCache(prevCache => ({
                        ...prevCache,
                        [newTaskId]: {
                            ...prevCache[newTaskId],
                            outbound_phone_number: cleanedOutboundPhoneNumber
                        }
                    }));
                } catch (error) {
                    addNotification("Failed to update the phone number: " + error.message, "error");
                }
            }
        };

        updatePhoneNumber();
    }, [outboundPhoneNumber, newTaskId, updateTask, addNotification, setTaskCache]);

    const handleBackToDashboard = () => {
        const route = determineRoute(RouteEnum.DASHBOARD);
        navigate(route);
    };

    const handleStartNewTask = async () => {
        setIsTaskStarting(true);
        try {
            let cleanedOutboundPhoneNumber = outboundPhoneNumber.replace(/[^\d]/g, "");

            // Ensure the number has 10 digits, as expected in US phone numbers
            if (cleanedOutboundPhoneNumber.length !== 10) {
                addNotification("Invalid phone number length. Expected 10 digits.");
            }

            // Add the +1 prefix for US numbers
            cleanedOutboundPhoneNumber = `+1${cleanedOutboundPhoneNumber}`;
            await startTask(newTaskId, cleanedOutboundPhoneNumber);
            setIsTaskStarted(true);
        } catch (error) {
            addNotification("Failed to start the task: " + error.message, "error");
        } finally {
            setIsTaskStarting(false);
        }
    };

    const handleDeleteTask = async () => {
        try {
            const taskId = newTaskId || task_id;
            await deleteTask(taskId);
            addNotification("Task deleted successfully", "success");
            const route = determineRoute(RouteEnum.DASHBOARD);
            navigate(route);
        } catch (error) {
            addNotification("Failed to delete the task: " + error.message, "error");
        }
    };

    return (
        <>
            <Header
                environment={baseURL?.includes("dev") ? "dev" : "prod"}
                respectUser={true}
            />
            <div className="new-task-container">
                {isLoading ? (
                    <Spinner backgroundColor="gray" color="white" />
                ) : (
                    <>
                        {error ? (
                            <div className="error-container">
                                <p className="error-message">{error}</p>
                                <ButtonWithLoading onClick={handleBackToDashboard}>Back to Dashboard</ButtonWithLoading>
                            </div>
                        ) : (
                            <div className="new-task-form-container">
                                <PhoneNumberInput outboundPhoneNumber={outboundPhoneNumber} setOutboundPhoneNumber={setOutboundPhoneNumber} />
                                <ChatWindow baseURL={baseURL} taskId={newTaskId} />
                                <div className="chat-button-container">
                                    <ButtonWithLoading onClick={() => { handleBackToDashboard() }} loading={false} disabled={false} backgroundColor="#e0e0e0" hoverColor="#d3d3d3" textColor="#000000">
                                        Back to Dashboard
                                    </ButtonWithLoading>

                                    <ButtonWithLoading onClick={() => { handleDeleteTask() }} loading={false} disabled={false} backgroundColor="#f44336" hoverColor="#b22222" textColor="#ffffff">
                                        Delete Task
                                    </ButtonWithLoading>

                                    <ButtonWithLoading onClick={() => { handleStartNewTask() }} loading={isTaskStarting} disabled={isTaskStarting || outboundPhoneNumber?.replace(/[^\d]/g, "").length < 10}>
                                        Start task now
                                    </ButtonWithLoading>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
}

export default NewTaskPage;