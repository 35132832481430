import React, { useEffect, useRef, useState, useCallback } from "react";
import { useAuth } from "../../utils/AuthContext";
import { useNotifications } from "../../components/Alert/Notification/NotificationContext";
import TaskItem from './TaskItem';
import Spinner from '../../components/Spinners/CircleSpinner/CirlceSpinner';
import { useApi } from "../../utils/apiContext";
import "./TaskList.css";

const TaskList = ({ baseURL, onInProgressTaskClick, onTaskSelect }) => {
    const { addNotification } = useNotifications();
    const { fetchTask } = useApi();
    const { sessionToken, reauthenticate } = useAuth();
    const [inProgressTasks, setInProgressTasks] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [nextCursor, setNextCursor] = useState(null); // Remove sessionStorage for cursor
    const [isFetchingMore, setIsFetchingMore] = useState(false);

    const listContainerRef = useRef(null);
    const [isLoadingTasks, setIsLoadingTasks] = useState(false);

    // Restore scroll position on mount
    useEffect(() => {
        const scrollPosition = parseInt(sessionStorage.getItem("scrollPosition"), 10) || 0;
        if (listContainerRef.current) {
            listContainerRef.current.scrollTop = scrollPosition;
        }

        initializeTasks();
    }, []);

    useEffect(() => {
        const wsUrl = baseURL?.includes("dev")
            ? 'wss://streaming-services-dev-ljp7j2j3ka-uc.a.run.app/task_update'
            : 'wss://streaming-services-prod-snex6bbboa-uc.a.run.app/task_update';

        const socket = new WebSocket(wsUrl);

        socket.onopen = () => {
            console.log('WebSocket connection established');
            socket.send(JSON.stringify({
                user_session_token: sessionToken,
                event: "connected",
                value: "WebSocket connection established"
            }));
        };

        socket.onmessage = async (event) => {
            const data = JSON.parse(event.data);
            console.log('Received data:', data);

            if (data?.error === "token_expired") {
                await reauthenticate();
                socket.send(JSON.stringify({
                    user_session_token: sessionToken,
                    event: "reconnected",
                    value: "WebSocket connection re-established"
                }));
            } else {
                setInProgressTasks(data.tasks);
            }
        };

        socket.onerror = (error) => console.error('WebSocket error:', error);
        socket.onclose = () => console.log('WebSocket closed');

        return () => socket.close(); // Clean up WebSocket on unmount
    }, [baseURL, sessionToken]);

    const initializeTasks = async () => {
        setIsLoadingTasks(true);
        try {
            const data = await fetchTask("completed", null, null, null);
            setTasks(data.tasks);
            setNextCursor(data.cursor);
        } catch (error) {
            addNotification("Failed to load tasks", "error");
        }
        setIsLoadingTasks(false);
    };

    const fetchMoreTasks = async () => {
        if (!nextCursor || isFetchingMore) return;

        setIsFetchingMore(true);
        try {
            const data = await fetchTask("completed", null, nextCursor, 10);
            setTasks((prevTasks) => [...prevTasks, ...data.tasks]);
            setNextCursor(data.cursor);
        } catch (error) {
            addNotification("Failed to load more tasks", "error");
        }
        setIsFetchingMore(false);
    };

    const handleScroll = useCallback(() => {
        if (
            listContainerRef.current.scrollTop + listContainerRef.current.clientHeight >=
            listContainerRef.current.scrollHeight - 10 // Small buffer to trigger early
        ) {
            fetchMoreTasks();
        }

        sessionStorage.setItem("scrollPosition", listContainerRef.current.scrollTop); // Cache scroll position
    }, [fetchMoreTasks]);

    return (
        <div
            className="task-list-container"
            ref={listContainerRef}
            onScroll={handleScroll}
        >
            {isLoadingTasks ? (
                <Spinner color="rgba(128, 128, 128, 0.5)" />
            ) : (
                <>
                    {inProgressTasks.length > 0 && (
                        <div className="in-progress-tasks">
                            <h3>In Progress Tasks</h3>
                            {inProgressTasks.map((task) => (
                                <TaskItem
                                    key={task.task_id}
                                    task={task}
                                    didSelect={() => onInProgressTaskClick(task.task_id)}
                                />
                            ))}
                        </div>
                    )}
                    <div className="completed-tasks">
                        <h3>Completed Tasks</h3>
                        {tasks.map((task) => (
                            <TaskItem
                                key={task.task_id}
                                task={task}
                                didSelect={() => onTaskSelect(task.task_id)}
                            />
                        ))}
                    </div>
                </>
            )}
            {isFetchingMore && <Spinner color="rgba(128, 128, 128, 0.5)" />}
        </div>
    );
};

export default TaskList;
