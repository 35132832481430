import FooterSection from "../../components/FooterSection/FooterSection";
import Header from "../../components/Header/Header";
import "./BlogPost.css";
import img1 from "./images/img1.png";
import img2 from "./images/img2.png";
import img3 from "./images/img3.png";
import img4 from "./images/img4.png";
import img5 from "./images/img5.png";
import authorImage from "./images/author.png";

const BlogPost = ({ baseURL }) => {
    return (
        <>
            <Header
                logoOnly={true}
                showWaitlistButton={true}
            />
            <h1 className="blog-title-h1">Transforming Frustration into Innovation: My Path from Patient to Healthcare Entrepreneur</h1>
            <div className="blog-post-container">
                <p>Life has a way of throwing curveballs when you least expect them. At 23, I was the picture of health - active, energetic, and blissfully unaware of the storm brewing within my own body. Then, seemingly overnight, everything changed.</p>
                <h2>The Day My World Tilted</h2>
                <p>It started with a limp. Innocuous at first, but impossible to ignore when I found myself stumbling over people's feet at a Leafs game. My right leg had become an uncooperative stranger. Soon after, a peculiar sensation crept in - my right leg felt perpetually cold, as if exposed to a phantom draft.</p>
                <p>As the days passed, the oddities multiplied. Suddenly, the simple act of plucking my AirPods from their case became a Herculean task. My fingers, once deft and precise, now fumbled clumsily.</p>
                <p>Despite these alarming symptoms, I remained oddly unconcerned. Twenty-three years of robust health had lulled me into a false sense of invincibility. Thankfully, my mother's intuition kicked in where mine had failed. Before I knew it, I was sitting in a doctor's office, watching confusion and concern flicker across his face as he examined me.</p>
                <p>The verdict came swiftly and without warning: Multiple Sclerosis. In that moment, I felt as if I'd been sucked into a vortex, the world around me fading to a muffled hush. In the span of a heartbeat, my life had irreversibly changed.</p>
                <h2>Living with MS: A Full Recovery and a New Perspective</h2>
                <p>Contrary to my initial fears, my MS diagnosis didn't spell the end of life as I knew it. In fact, I fully recovered from that first alarming attack. The limp disappeared, my coordination returned, and that odd cold sensation vanished. But while the symptoms retreated, the diagnosis remained, introducing a new rhythm to my days - regular MRIs, doctor's visits, blood work, and medication. I was determined not to let it define me.</p>
                <p>My new normal revolves around a cocktail of healthy habits and a twice-daily dose of dimethyl fumarate, a disease-modifying therapy. It's not always a walk in the park - sometimes it turns my face an impressive shade of red for 15 minutes - but it's a small price to pay for maintaining my health and preventing future attacks. This regimen, along with my full recovery from the initial episode, has allowed me to continue living an active, fulfilling life, virtually indistinguishable from before my diagnosis.</p>
                <h2>A Tale of Two Healthcare Systems</h2>
                <p>For years, managing my condition in Canada was a breeze. Quarterly blood work, medication delivered to my doorstep every two months - clockwork efficiency that I naively assumed was universal.</p>
                <p>Then came the plot twist: a job at Snapchat and a move to Los Angeles. Suddenly, I found myself navigating the labyrinthine American healthcare system, and my rose-tinted glasses quickly shattered.</p>
                <h2>Welcome to the Specialty Pharmacy Nightmare</h2>
                <p>In the U.S., my lifeline - dimethyl fumarate - is only available through specialty pharmacies. Little did I know I was stepping into a healthcare twilight zone.</p>
                <p>To give you a taste of what I'm dealing with, let's look at the customer ratings for some of the largest specialty pharmacies:</p>
                <ul>
                    <li>CVS Specialty ($73.3 billion revenue): <a href="https://www.yelp.com/biz/cvs-specialty-pharmacy-san-francisco" target="_blank" rel="noopener noreferrer">1.3 stars on Yelp (240 reviews) <i style={{ fontSize: '12px' }} className="fa">&#xf08e;</i></a><img alt="" src={img1} title="" /></li>
                    <li>Accredo ($59.5 billion revenue): <a href="https://www.yelp.com/biz/accredo-health-group-memphis" target="_blank" rel="noopener noreferrer">1.1 stars on Yelp (918 reviews) <i style={{ fontSize: '12px' }} className="fa">&#xf08e;</i></a><img alt="" src={img2} title="" /></li>
                    <li>Optum Specialty Pharmacy ($32.3 billion revenue): <a href="https://www.yelp.com/biz/optumrx-mail-order-pharmacy-carlsbad" target="_blank" rel="noopener noreferrer">1.2 stars on Yelp (394 reviews) <i style={{ fontSize: '12px' }} className="fa">&#xf08e;</i></a><img alt="" src={img3} title="" /></li>
                    <li>AllianceRx ($8.4 billion revenue): <a href="https://www.yelp.com/biz/alliancerx-walgreens-prime-canton?start=120" target="_blank" rel="noopener noreferrer">1.1 stars on Yelp (168 reviews) <i style={{ fontSize: '12px' }} className="fa">&#xf08e;</i></a><img alt="" src={img4} title="" /></li>
                    <li>Welldyne ($750 Million revenue - this is the pharmacy I use.): <a href="https://www.yelp.com/biz/welldyne-lakeland-2" target="_blank" rel="noopener noreferrer">1.9 stars on Yelp (9 reviews) <i style={{ fontSize: '12px' }} className="fa">&#xf08e;</i></a><img alt="" src={img5} title="" /></li>
                </ul>
                <p>These abysmal ratings aren't just numbers - they represent countless hours of frustration, missed medications, and needless suffering for patients already dealing with serious health issues.</p>
                <h2>The Broken System Behind the Broken Service</h2>
                <p>Why are these pharmacies so terrible? The answer lies in a convoluted chain of misaligned incentives.</p>
                <p>As a patient, you'd think you're the customer. You're not. The real customer is the Pharmacy Benefit Manager (PBM), who in turn answers to your insurance company, who is selected by your employer. In this twisted game of telephone, the patient's voice is lost in the noise.</p>
                <p>The result? Specialty pharmacies have zero incentive to provide good service to patients. We're captive consumers, stuck in a system that views us as little more than a prescription number.</p>
                <h2>From Frustration to Innovation: The Birth of Tagin</h2>
                <p>After countless hours wasted on hold, hundreds of circular conversations, and too many close calls with missed medications, I'd had enough. If the system wouldn't change for us, we needed to change how we interact with the system.</p>
                <p>That's how <a href="https://tagin.ai/">Tagin</a> was born.</p>
                <p>Tagin is an AI-powered assistant that navigates the specialty pharmacy maze on your behalf. Instead of you spending hours on the phone, our AI does the heavy lifting. It asks you a few questions about what you need, then makes the calls, handles the back-and-forth, and gets things done.</p>
                <h2>A Glimpse of a Better Future</h2>
                <p>For the past few weeks, I've been using Tagin myself, and the difference is night and day. No more time wasted on hold. No more explaining my situation to a new person every time I call. Just simple, effective healthcare management.</p>
                <p>Now, we're ready to share Tagin with others trapped in the specialty pharmacy labyrinth. If you're tired of fighting this broken system alone, we're here to help.</p>
                <p>Tagin is currently free for beta users, and we plan to keep the subscription fee under $20/month when we launch publicly.</p>
                <p>This is just the beginning. While Tagin can't fix the entire healthcare system, it can make life significantly easier for those of us dealing with chronic conditions. By giving patients back their time and peace of mind, we're taking a small but crucial step towards a more patient-friendly healthcare system.</p>
                <p>Ready to reclaim your time and sanity? Sign up for Tagin or <a href="mailto:jacob@santasailab.com">reach out to me directly</a>. Let's navigate this broken system together, and maybe, just maybe, start to change it for the better.</p>
                <div className="author-bio">
                    <img src={authorImage} alt="Jacob Catalano" />
                    <div className="author-bio-text">
                        <p><strong>Author:</strong> Jacob Catalano</p>
                        <p><strong>Published on:</strong> October 21, 2024</p>
                    </div>
                </div>
            </div>
            <FooterSection />
        </>
    );
};

export default BlogPost;