import React, { useState, useEffect } from 'react';
import './DetailTaskItem.css';
import { useApi } from '../../utils/apiContext';
import { useNotifications } from '../../components/Alert/Notification/NotificationContext';
import ButtonWithLoading from '../../components/Buttons/GeneralButton/BaseButton';
import Header from "../../components/Header/Header";
import { useRoute } from "../../utils/RouteContext";
import { useParams, useNavigate } from 'react-router-dom';
import { useTaskCache } from './TaskCacheContext';
import Spinner from '../../components/Spinners/CircleSpinner/CirlceSpinner';

const DetailTaskItem = ({ baseURL }) => {
    const { fetchTask, deleteTask, fetchTaskWithCalls } = useApi();
    const { addNotification } = useNotifications();
    const [isLoading, setIsLoading] = useState(true); // Initialize loading to true
    const { determineRoute, RouteEnum } = useRoute();
    const { task_id } = useParams();
    const { taskCache, setTaskCache } = useTaskCache();
    const [task, setTask] = useState(null);
    const [expandedCallIndex, setExpandedCallIndex] = useState(null); // State to track expanded call
    const navigate = useNavigate();

    useEffect(() => {
        if (taskCache[task_id]) {
            setTask(taskCache[task_id]);
            setIsLoading(false); // Set loading to false if task is cached
            return;
        }

        const fetchTaskDetails = async () => {
            try {
                const taskDetails = await fetchTaskWithCalls(task_id);
                setTask(taskDetails.task);
                setTaskCache(prevCache => ({ ...prevCache, [task_id]: taskDetails.task }));
            } catch (error) {
                console.error("Failed to fetch task details:", error);
                addNotification("Failed to fetch task details", "error");
            } finally {
                setIsLoading(false); // Ensure loading is set to false after fetching
            }
        };

        fetchTaskDetails();
    }, [task_id, taskCache, setTaskCache]);

    const handleDelete = async () => {
        setIsLoading(true);
        try {
            await deleteTask(task_id);
            addNotification("Task deleted successfully", "success");
            navigateToDashboard();
            setTaskCache(prevCache => {
                const newCache = { ...prevCache };
                delete newCache[task_id];
                return newCache;
            });
        } catch (error) {
            console.error("Failed to delete task:", error);
            addNotification("Failed to delete task", "error");
        } finally {
            setIsLoading(false);
        }
    };

    const newTaskClicked = () => {
        const route = determineRoute(RouteEnum.NEW_TASK);
        navigate(route);
    };

    const navigateToDashboard = () => {
        const route = determineRoute(RouteEnum.DASHBOARD);
        navigate(route);
    };

    const backgroundColor = '#f44336';
    const hoverColor = '#b22222';

    const toggleCallExpand = (index) => {
        setExpandedCallIndex(expandedCallIndex === index ? null : index);
    };

    return (
        <>
            <Header
                environment={baseURL?.includes("dev") ? "dev" : "prod"}
                respectUser={true}
                onNewTask={newTaskClicked}
            />
            <div className="task-container">
                {isLoading ? (
                    <Spinner color="rgba(128, 128, 128, 0.5)" />
                ) : (
                    <div className="detail-task-container">
                        <button className="close-button" onClick={navigateToDashboard}>×</button>
                        <p><strong>Date:</strong> {new Date(task.date_created).toLocaleString()}</p>
                        <p><strong>Status:</strong> {task.status}</p>
                        <p><strong>Description:</strong> {task.description}</p>
                        <p><strong>Result:</strong> {task.result}</p>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <ButtonWithLoading
                                className="delete-button"
                                onClick={handleDelete}
                                loading={isLoading}
                                backgroundColor={backgroundColor}
                                hoverColor={hoverColor}
                                style={{ fontSize: '0.8rem' }}
                            >
                                Delete Task
                            </ButtonWithLoading>
                        </div>
                    </div>
                )}
            </div>
            {!isLoading && task.calls && task.calls.length > 0 && (
                <div className="detail-calls-container">
                    <h3>Calls</h3>
                    {task.calls.map((call, index) => (
                        <div key={index} className="call-item" onClick={() => toggleCallExpand(index)}>
                            <span>Call {index + 1}: </span>
                            {call.states.length > 0 && <span>
                                <strong>{call.states[call.states.length - 1].state}</strong> - {new Date(call.states[call.states.length - 1].timestamp).toLocaleString()}
                            </span>}
                            {expandedCallIndex === index && (
                                <div>
                                    <p><strong>Outbound Phone Number:</strong> {call.outbound_phone_number}</p>
                                    <p><strong>Outcome:</strong> {call.outcome}</p>
                                    <p><strong>Task ID:</strong> {call.task_id}</p>
                                    <p><strong>States:</strong></p>
                                    {call.states.map((stateObj, stateIndex) => (
                                        <p key={stateIndex}>
                                            <strong>- {stateObj.state}</strong> - {new Date(stateObj.timestamp).toLocaleString()}
                                        </p>
                                    ))}

                                    <div className="transcript">
                                        <h4>Transcript</h4>
                                        <div style={{ color: '#d3d3d3' }}>----------------------------------------------------------------</div>
                                        {call.transcript.map((transcriptObj, transcriptIndex) => (
                                            <p key={transcriptIndex} className={transcriptObj.role === "user" ? "user-message" : "assistant-message"}>
                                                <strong>- {transcriptObj.role === "user" ? "recipient" : "tagin"}</strong> - {transcriptObj.content}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default DetailTaskItem;
