import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../utils/AuthContext';
import { HashLink } from 'react-router-hash-link';
import './Header.css';
import Logo from '../../assets/logo.png';
import ButtonWithLoading from '../Buttons/GeneralButton/BaseButton';
import { useRoute } from '../../utils/RouteContext';

const Header = ({ environment, respectUser = false, logoOnly = false, showWaitlistButton = false, onNewTask }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const navigate = useNavigate();
    const { determineRoute, RouteEnum } = useRoute();
    const { user, logout } = useAuth();

    const handleProfileClick = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleLogOut = async () => {
        await logout();
        setIsDropdownOpen(false);
        const path = determineRoute(RouteEnum.HOME);
        navigate(path);
    };

    const handleLogoClick = () => {
        const path = user ? determineRoute(RouteEnum.DASHBOARD) : determineRoute(RouteEnum.HOME);
        navigate(path);
    };

    const handleEditProfileClick = () => {
        const path = determineRoute(RouteEnum.PROFILE);
        navigate(path);
    }

    const handleDashboardClick = () => {
        const path = determineRoute(RouteEnum.DASHBOARD);
        navigate(path);
    }

    return (
        <header className="header">
            <div className="header-left">
                <div onClick={() => { handleLogoClick() }} className="logo-container">
                    <img src={Logo} alt="Tagin Logo" className="logo" />
                    {environment === 'dev' && (
                        <span className="environment-mark">DEV</span>
                    )}
                </div>
                {logoOnly && <span onClick={() => { handleLogoClick() }} className="company-name" style={{ marginLeft: '-20px' }}>Tagin</span>}
                {!logoOnly && (
                    <nav className="header-nav">
                        {(!respectUser || !user) && (
                            <>
                                <HashLink smooth to="#brands">Brands</HashLink>
                                <HashLink smooth to="#how-it-works">How It Works</HashLink>
                                <HashLink smooth to="/blog-post/1">Blog</HashLink>
                                <HashLink smooth to="#faq">FAQ</HashLink>
                                <HashLink smooth to="#contact-us">Contact Us</HashLink>
                            </>
                        )}
                    </nav>
                )}
                <div className="header-nav-mobile">
                    {!logoOnly && (!respectUser || !user) && (
                        <HashLink smooth to="/blog-post/1">Blog</HashLink>
                    )}
                </div>
            </div>
            {!logoOnly && <>
                {(respectUser && user) && (
                    <div className="header-right">
                        {onNewTask && (<ButtonWithLoading
                            className="new-task-button"
                            onClick={onNewTask}
                            height="30px"
                        >
                            + New Task
                        </ButtonWithLoading>
                        )}
                        <div className="profile">
                            <div className="profile-container">
                                <img
                                    src={user.photoURL}
                                    alt="Profile"
                                    onClick={handleProfileClick}
                                    className="profile-image"
                                />
                                {isDropdownOpen && (
                                    <div className="dropdown">
                                        <button onClick={handleDashboardClick}>Dashboard</button>
                                        <button onClick={handleEditProfileClick}>Profile</button>
                                        <button onClick={handleLogOut}>Log Out</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </>}
            {showWaitlistButton && (
                <ButtonWithLoading
                    backgroundColor="#2ECC71"
                    hoverColor="#1e7a45"
                    height="36px"
                    onClick={() => { handleLogoClick() }}
                >
                    Join the waitlist
                </ButtonWithLoading>
            )}
        </header>
    );
};

export default Header;
