import React, { useState } from "react";
import "./WaitlistInput.css";
import mailIcon from "./images/mail.svg";
import { useNotifications } from "../../../Alert/Notification/NotificationContext";
import { useApi } from "../../../../utils/apiContext";
import ButtonWithLoading from "../../../Buttons/GeneralButton/BaseButton";


const WaitlistInput = ({ baseURL }) => {
    const { addNotification } = useNotifications();
    const [email, setEmail] = useState("");
    const { joinWaitlist } = useApi();
    const [loading, setLoading] = useState(false);
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleJoinWaitlist = async () => {
        console.log(email);

        if (!email) {
            addNotification("Please enter an email", "error");
            return;
        }

        if (!email.includes("@")) {
            addNotification("Please enter a valid email", "error");
            return;
        }

        setLoading(true);

        try {
            const data = await joinWaitlist(email);

            if (data.error) {
                console.log(data.error);
                if (data.error === "EMAIL_ALREADY_ON_WAITLIST") {
                    addNotification("You are already on the waitlist", "error");
                } else {
                    addNotification("Couldn't complete your request, please try again later.", "error");
                }
            }

            if (data.status) {
                setEmail("");
                addNotification("Added!", "success");
            }

            if (window.gtag) {
                window.gtag('event', 'conversion', {
                    'send_to': 'AW-16750552471/wzZ5COqQuuAZEJfLpLM-',
                    'value': 1.0,
                    'currency': 'USD'
                });
            }

        } catch (error) {
            console.error('Error:', error);
            addNotification("Failed", "error");
        }

        setLoading(false);
    };

    return (
        <div className="email-signup">
            <div className="email-signup-container">
                <div className="email-form">
                    <div className="email-input-container">
                        <img src={mailIcon} alt="Mail Icon" className="icon" />
                        <input
                            type="email"
                            className="email-input"
                            placeholder="Enter your email"
                            value={email}
                            onChange={handleEmailChange}
                        />
                    </div>
                    <div className="waitlist-button">
                        <ButtonWithLoading
                            onClick={() => handleJoinWaitlist()}
                            loading={loading}
                            backgroundColor="#2ECC71"
                            hoverColor="#1e7a45"
                            type="submit"
                        >
                            Join the Waitlist
                        </ButtonWithLoading>
                    </div>
                </div>
                <p className="signup-description">
                    Already have an invite code?  <a href="/redeem">Get started</a>
                </p>
            </div>
        </div>
    );
};

export default WaitlistInput;