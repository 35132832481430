import React, { useState, useEffect } from 'react';
import './Profile.css';
import ButtonWithLoading from '../../components/Buttons/GeneralButton/BaseButton';
import { useApi } from '../../utils/apiContext';
import { useNotifications } from '../../components/Alert/Notification/NotificationContext';
import Header from '../../components/Header/Header';
import ProfileEditPersonalInformation from './ProfileEditPersonalInformation';
import ProfileAddDoctorInformation from './ProfileAddDoctorInformation';
import ProfileAddInsuranceInformation from './ProfileAddInsuranceInformation';
import ProfileAddMedicationInformation from './ProfileAddMedicationInformation';
import CirlceSpinner from '../../components/Spinners/CircleSpinner/CirlceSpinner';

const EditProfile = ({ isOnboarding, baseURL }) => {
    const { getUserProfile } = useApi();
    const { addNotification } = useNotifications();
    const [user, setUser] = useState(null);
    const [isLoadingProfile, setIsLoadingProfile] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [addingType, setAddingType] = useState(null);

    useEffect(() => {
        const fetchUserProfile = async () => {
            setIsLoadingProfile(true);
            try {
                const data = await getUserProfile();

                if (data.error) {
                    addNotification(data.error, "error");
                }

                if (data.status) {
                    setUser(data.user);
                    console.log(data.user);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoadingProfile(false);
            }
        }

        fetchUserProfile();
    }, []);

    const formatPhoneNumber = (value) => {
        if (!value) return value;
        // Remove all non-digits from the value
        const phoneNumber = value.replace(/[^\d]/g, "");

        // Format the phone number
        const phoneNumberLength = phoneNumber.length;

        if (phoneNumberLength < 4) return phoneNumber;

        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }

        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    };

    const handleAddMedication = () => {
        setIsDropdownOpen(false);
        setAddingType("medication");
    }

    const handleAddInsurance = () => {
        setIsDropdownOpen(false);
        setAddingType("insurance");
    }

    const handleAddDoctor = () => {
        setIsDropdownOpen(false);
        setAddingType("doctor");
    }

    const addedNewMedicalInformation = async () => {
        try {
            const data = await getUserProfile();
            if (data.error) {
                console.error(data.error);
            }

            if (data.status) {
                setUser(data.user);
                setAddingType(null);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className='edit-profile-container'>
            <Header
                environment={baseURL?.includes("dev") ? "dev" : "prod"}
                respectUser={true}
            />
            {isLoadingProfile ? (
                <div className='profile-loading-spinner-container'>
                    <CirlceSpinner color="rgba(128, 128, 128, 0.5)" />
                </div>
            ) : (user &&
                <>
                    <div className="edit-profile">
                        <h2>{isOnboarding ? "Onboarding" : "Profile"}</h2>
                        <ProfileEditPersonalInformation isOnboarding={isOnboarding} user={user} />
                        <div className='profile-medical-info-container'>
                            <div className='profile-medical-info-container-header'>
                                <h4>Medical Information</h4>
                                <div className='profile-dropdown-container'>
                                    <button onClick={() => setIsDropdownOpen(!isDropdownOpen)}>Add +</button>
                                    {isDropdownOpen && (
                                        <div className="profile-dropdown">
                                            <button onClick={handleAddMedication}>Add Medication</button>
                                            <button onClick={handleAddInsurance}>Add Insurance</button>
                                            <button onClick={handleAddDoctor}>Add Doctor</button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {user.medications || user.insurances || user.doctors ? (
                                <>
                                    <div className='profile-medical-info-container-item-section'>
                                        {user.medications && user.medications.map((medication, index) => (
                                            <div key={index} className='profile-medical-info-container-item-medication'>
                                                <h5>medication</h5>
                                                <p>medication name: {medication.medication_name ? medication.medication_name : "--"}</p>
                                                <p>prescription number: {medication.prescription_number ? medication.prescription_number : "--"}</p>
                                                <p>dosage: {medication.dosage ? medication.dosage : "--"}</p>
                                                <p>usage: {medication.usage ? medication.usage : "--"}</p>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='profile-medical-info-container-item-section'>
                                        {user.insurances && user.insurances.map((insurance, index) => (
                                            <div key={index} className='profile-medical-info-container-item-insurance'>
                                                <h5>insurance</h5>
                                                <p>provider name: {insurance.provider_name ? insurance.provider_name : "--"}</p>
                                                <p>member id: {insurance.member_id_number ? insurance.member_id_number : "--"}</p>
                                                <p>group number: {insurance.group_number ? insurance.group_number : "--"}</p>
                                                <p>BIN number: {insurance.bin_number ? insurance.bin_number : "--"}</p>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='profile-medical-info-container-item-section'>
                                        {user.doctors && user.doctors.map((doctor, index) => (
                                            <div key={index} className='profile-medical-info-container-item-doctor'>
                                                <h5>doctor</h5>
                                                <p>name: {doctor.full_name ? doctor.full_name : "--"}</p>
                                                {doctor.address && (
                                                    <p>address: {doctor.address.address_line_1}{doctor.address.address_line_2 ? `, ${doctor.address.address_line_2}` : ""}, {doctor.address.city}, {doctor.address.state}, {doctor.address.zip_code}</p>
                                                )}
                                                <p>phone number: {doctor.phone_number ? formatPhoneNumber(doctor.phone_number) : "--"}</p>
                                                <p>email: {doctor.email ? doctor.email : "--"}</p>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            ) : (
                                <h5>No medical information added yet.</h5>
                            )}
                        </div>
                    </div>
                </>
            )}
            {addingType && (
                <div className='add-profile-form-overlay-container'>
                    <div className='add-profile-form-overlay-background-container'>
                        <div className="add-profile-form-container-entry">
                            <div className="add-profile-form-container-entry-header">
                                <h4>Add {addingType.charAt(0).toUpperCase() + addingType.slice(1).toLowerCase()}</h4>
                                <button className="close-button" onClick={() => setAddingType(null)}>x</button>
                            </div>
                            {addingType === "doctor" && <ProfileAddDoctorInformation onSuccess={() => addedNewMedicalInformation()} />}
                            {addingType === "insurance" && <ProfileAddInsuranceInformation onSuccess={() => addedNewMedicalInformation()} />}
                            {addingType === "medication" && <ProfileAddMedicationInformation onSuccess={() => addedNewMedicalInformation()} />}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EditProfile;