import React, { useState } from 'react';
import './ProfileAddInsuranceInformation.css';
import ButtonWithLoading from '../../components/Buttons/GeneralButton/BaseButton';
import { useApi } from '../../utils/apiContext';
import { useNotifications } from '../../components/Alert/Notification/NotificationContext';

const ProfileAddInsuranceInformation = ({ onSuccess }) => {
    const { updateUserProfile } = useApi();
    const { addNotification } = useNotifications();

    const [form, setForm] = useState({
        insuranceProviderName: "",
        memberIdNumber: "",
        groupNumber: "",
        binNumber: "",
    });

    const [saveButtonIsLoading, setSaveButtonIsLoading] = useState(false);
    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    }

    const handleSave = async () => {
        let jsonBody = {}

        if (form.insuranceProviderName) {
            jsonBody['provider_name'] = form.insuranceProviderName;
        }

        if (form.memberIdNumber) {
            jsonBody['member_id_number'] = form.memberIdNumber;
        }

        if (form.groupNumber) {
            jsonBody['group_number'] = form.groupNumber;
        }

        if (form.binNumber) {
            jsonBody['bin_number'] = form.binNumber;
        }

        if (Object.keys(jsonBody).length <= 0) {
            addNotification("Please fill in the required fields", "error");
            return;
        }

        setSaveButtonIsLoading(true);

        try {
            const data = await updateUserProfile({ insurances: [jsonBody] });

            if (data.error) {
                console.log(data.error);
                addNotification("Couldn't complete your request, please try again later.", "error");
            }

            if (data.status) {
                addNotification("Added!", "success");
                onSuccess();
            }
        } catch (error) {
            console.error('Error:', error);
            addNotification("Failed", "error");
        } finally {
            setSaveButtonIsLoading(false);
        }
    }

    return (
        <div className='profile-add-insurance-information-container'>
            <div className="profile-add-insurance-information-form-horizontal-group">
                <div className="profile-add-insurance-information-form-input-section">
                    <label>Provider Name</label>
                    <input
                        type="text"
                        name="insuranceProviderName"
                        placeholder="Insurance Provider Name"
                        value={form.insuranceProviderName}
                        onChange={handleChange}
                    />
                </div>
                <div className="profile-add-insurance-information-form-input-section">
                    <label>Member ID Number</label>
                    <input
                        type="text"
                        name="memberIdNumber"
                        placeholder="Member ID Number"
                        value={form.memberIdNumber}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="profile-add-insurance-information-form-horizontal-group">
                <div className="profile-add-insurance-information-form-input-section">
                    <label>Group Number</label>
                    <input
                        type="text"
                        name="groupNumber"
                        placeholder="Group Number"
                        value={form.groupNumber}
                        onChange={handleChange}
                    />
                </div>
                <div className="profile-add-insurance-information-form-input-section">
                    <label>BIN Number</label>
                    <input
                        type="text"
                        name="binNumber"
                        placeholder="BIN Number"
                        value={form.binNumber}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <ButtonWithLoading
                onClick={handleSave}
                loading={saveButtonIsLoading}
            >
                Save
            </ButtonWithLoading>
        </div>
    );
}

export default ProfileAddInsuranceInformation;