import React, { useState } from 'react';
import './ProfileAddMedicationInformation.css';
import ButtonWithLoading from '../../components/Buttons/GeneralButton/BaseButton';
import { useApi } from '../../utils/apiContext';
import { useNotifications } from '../../components/Alert/Notification/NotificationContext';

const ProfileAddMedicationInformation = ({ onSuccess }) => {
    const { updateUserProfile } = useApi();
    const { addNotification } = useNotifications();

    const [form, setForm] = useState({
        prescriptionNumber: "",
        medicationName: "",
        dosage: "",
        usage: "",
    });

    const [saveButtonIsLoading, setSaveButtonIsLoading] = useState(false);

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    }

    const handleSave = async () => {
        let jsonBody = {}

        if (form.prescriptionNumber) {
            jsonBody['prescription_number'] = form.prescriptionNumber;
        }

        if (form.medicationName) {
            jsonBody['medication_name'] = form.medicationName;
        }

        if (form.dosage) {
            jsonBody['dosage'] = form.dosage;
        }

        if (form.usage) {
            jsonBody['usage'] = form.usage;
        }

        if (Object.keys(jsonBody).length <= 0) {
            addNotification("Please fill in the required fields", "error");
            return;
        }

        setSaveButtonIsLoading(true);

        try {
            const data = await updateUserProfile({ medications: [jsonBody] });

            if (data.error) {
                console.log(data.error);
                addNotification("Couldn't complete your request, please try again later.", "error");
            }

            if (data.status) {
                addNotification("Added!", "success");
                onSuccess();
            }
        } catch (error) {
            console.error('Error:', error);
            addNotification("Failed", "error");
        } finally {
            setSaveButtonIsLoading(false);
        }
    }

    return (
        <div className='profile-add-medication-information-container'>
            <div className="profile-add-medication-information-form-horizontal-group">
                <div className="profile-add-medication-information-form-input-section">
                    <label>Prescription Number</label>
                    <input
                        type="text"
                        name="prescriptionNumber"
                        placeholder="Prescription Number"
                        value={form.prescriptionNumber}
                        onChange={handleChange}
                    />
                </div>
                <div className="profile-add-medication-information-form-input-section">
                    <label>Medication Name</label>
                    <input
                        type="text"
                        name="medicationName"
                        placeholder="Medication Name"
                        value={form.medicationName}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="profile-add-medication-information-form-horizontal-group">
                <div className="profile-add-medication-information-form-input-section">
                    <label>Dosage</label>
                    <input
                        type="text"
                        name="dosage"
                        placeholder="Dosage"
                        value={form.dosage}
                        onChange={handleChange}
                    />
                </div>
                <div className="profile-add-medication-information-form-input-section">
                    <label>Usage</label>
                    <input
                        type="text"
                        name="usage"
                        placeholder="Usage"
                        value={form.usage}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <ButtonWithLoading
                onClick={handleSave}
                loading={saveButtonIsLoading}
            >
                Save
            </ButtonWithLoading>
        </div>
    );
}

export default ProfileAddMedicationInformation;