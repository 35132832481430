import React, { useState } from 'react';
import './ProfileAddDoctorInformation.css';
import ButtonWithLoading from '../../components/Buttons/GeneralButton/BaseButton';
import { useApi } from '../../utils/apiContext';
import { useNotifications } from '../../components/Alert/Notification/NotificationContext';

const ProfileAddDoctorInformation = ({ onSuccess }) => {
    const { updateUserProfile } = useApi();
    const { addNotification } = useNotifications();

    const stateOptions = [
        { label: 'Alabama', value: 'AL' },
        { label: 'Alaska', value: 'AK' },
        { label: 'Arizona', value: 'AZ' },
        { label: 'Arkansas', value: 'AR' },
        { label: 'California', value: 'CA' },
        { label: 'Colorado', value: 'CO' },
        { label: 'Connecticut', value: 'CT' },
        { label: 'Delaware', value: 'DE' },
        { label: 'Florida', value: 'FL' },
        { label: 'Georgia', value: 'GA' },
        { label: 'Hawaii', value: 'HI' },
        { label: 'Idaho', value: 'ID' },
        { label: 'Illinois', value: 'IL' },
        { label: 'Indiana', value: 'IN' },
        { label: 'Iowa', value: 'IA' },
        { label: 'Kansas', value: 'KS' },
        { label: 'Kentucky', value: 'KY' },
        { label: 'Louisiana', value: 'LA' },
        { label: 'Maine', value: 'ME' },
        { label: 'Maryland', value: 'MD' },
        { label: 'Massachusetts', value: 'MA' },
        { label: 'Michigan', value: 'MI' },
        { label: 'Minnesota', value: 'MN' },
        { label: 'Mississippi', value: 'MS' },
        { label: 'Missouri', value: 'MO' },
        { label: 'Montana', value: 'MT' },
        { label: 'Nebraska', value: 'NE' },
        { label: 'Nevada', value: 'NV' },
        { label: 'New Hampshire', value: 'NH' },
        { label: 'New Jersey', value: 'NJ' },
        { label: 'New Mexico', value: 'NM' },
        { label: 'New York', value: 'NY' },
        { label: 'North Carolina', value: 'NC' },
        { label: 'North Dakota', value: 'ND' },
        { label: 'Ohio', value: 'OH' },
        { label: 'Oklahoma', value: 'OK' },
        { label: 'Oregon', value: 'OR' },
        { label: 'Pennsylvania', value: 'PA' },
        { label: 'Rhode Island', value: 'RI' },
        { label: 'South Carolina', value: 'SC' },
        { label: 'South Dakota', value: 'SD' },
        { label: 'Tennessee', value: 'TN' },
        { label: 'Texas', value: 'TX' },
        { label: 'Utah', value: 'UT' },
        { label: 'Vermont', value: 'VT' },
        { label: 'Virginia', value: 'VA' },
        { label: 'Washington', value: 'WA' },
        { label: 'West Virginia', value: 'WV' },
        { label: 'Wisconsin', value: 'WI' },
        { label: 'Wyoming', value: 'WY' },
    ];

    const [form, setForm] = useState({
        doctorFirstName: "",
        doctorLastName: "",
        doctorEmail: "",
        doctorPhoneNumber: "",
        doctorAddressLine1: "",
        doctorAddressLine2: "",
        doctorCity: "",
        doctorState: "AL",
        doctorZipCode: "",
    });

    const [saveButtonIsLoading, setSaveButtonIsLoading] = useState(false);

    const formatPhoneNumber = (value) => {
        if (!value) return value;
        // Remove all non-digits from the value
        const phoneNumber = value.replace(/[^\d]/g, "");

        // Format the phone number
        const phoneNumberLength = phoneNumber.length;

        if (phoneNumberLength < 4) return phoneNumber;

        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }

        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    };

    const handleZipCodeInputChange = (e) => {
        const { name, value } = e.target;
        // Allow only numbers and limit to 5 characters
        if (/^\d{0,5}$/.test(value)) {
            setForm({ ...form, [name]: value });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'doctorPhoneNumber') {
            const formattedPhoneNumber = formatPhoneNumber(value);
            setForm({ ...form, [name]: formattedPhoneNumber });
        } else if (name === 'doctorZipCode') {
            handleZipCodeInputChange(e);
        } else {
            setForm({ ...form, [name]: value });
        }
    };

    const handleSave = async () => {
        let jsonBody = {}

        if (form.doctorFirstName || form.doctorLastName) {
            let fullName = ''
            if (form.doctorFirstName) {
                fullName += form.doctorFirstName;
            }

            if (form.doctorLastName) {
                fullName += ' ' + form.doctorLastName;
            }

            if (fullName) {
                jsonBody['full_name'] = fullName;
            }
        }

        if (form.doctorEmail) {
            jsonBody['email'] = form.doctorEmail;
        }

        if (form.doctorPhoneNumber) {
            jsonBody['phone_number'] = form.doctorPhoneNumber.replace(/\D/g, '');;
        }

        if (form.doctorAddressLine1 && form.doctorCity && form.doctorState && form.doctorZipCode) {
            let addressJson = {}
            addressJson['address_line_1'] = form.doctorAddressLine1;
            addressJson['address_line_2'] = form.doctorAddressLine2;
            addressJson['city'] = form.doctorCity;
            addressJson['state'] = form.doctorState;
            addressJson['zip_code'] = form.doctorZipCode;
            jsonBody['address'] = addressJson;
        }

        if (Object.keys(jsonBody).length <= 0) {
            addNotification("Please fill in the required fields", "error");
            return;
        }

        setSaveButtonIsLoading(true);

        try {
            const data = await updateUserProfile({ doctors: [jsonBody] });

            if (data.error) {
                console.log(data.error);
                addNotification("Couldn't complete your request, please try again later.", "error");
            }

            if (data.status) {
                addNotification("Added!", "success");
                onSuccess();
            }
        } catch (error) {
            console.error('Error:', error);
            addNotification("Failed", "error");
        } finally {
            setSaveButtonIsLoading(false);
        }
    }

    return (
        <div className="profile-add-doctor-information-container">
            <div className="profile-add-doctor-information-form-horizontal-group">
                <div className="profile-add-doctor-information-form-input-section">
                    <label>First Name</label>
                    <input
                        type="text"
                        name="doctorFirstName"
                        placeholder="First Name"
                        value={form.doctorFirstName}
                        onChange={handleChange}
                    />
                </div>
                <div className="profile-add-doctor-information-form-input-section">
                    <label>Last Name</label>
                    <input
                        type="text"
                        name="doctorLastName"
                        placeholder="Last Name"
                        value={form.doctorLastName}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="profile-add-doctor-information-form-horizontal-group">
                <div className="profile-add-doctor-information-form-input-section">
                    <label>Email</label>
                    <input
                        type="email"
                        name="doctorEmail"
                        placeholder="Email"
                        value={form.doctorEmail}
                        onChange={handleChange}
                    />
                </div>
                <div className="profile-add-doctor-information-form-input-section">
                    <label>Phone Number</label>
                    <input
                        type="text"
                        name="doctorPhoneNumber"
                        placeholder="Phone Number"
                        value={form.doctorPhoneNumber}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="profile-add-doctor-information-form-input-section">
                <label>Address Line 1</label>
                <input
                    type="text"
                    name="doctorAddressLine1"
                    placeholder="Address Line 1"
                    value={form.doctorAddressLine1}
                    onChange={handleChange}
                />
            </div>
            <div className="profile-add-doctor-information-form-input-section">
                <label>Address Line 2</label>
                <input
                    type="text"
                    name="doctorAddressLine2"
                    placeholder="Address Line 2"
                    value={form.doctorAddressLine2}
                    onChange={handleChange}
                />
            </div>
            <div className="profile-add-doctor-information-form-horizontal-group">
                <div className="profile-add-doctor-information-form-input-section">
                    <label>City</label>
                    <input
                        type="text"
                        name="doctorCity"
                        placeholder="City"
                        value={form.doctorCity}
                        onChange={handleChange}
                    />
                </div>
                <div className="profile-add-doctor-information-form-input-section">
                    <label>State</label>
                    <div className="state-select-container">
                        <select
                            name="doctorState"
                            value={form.doctorState}
                            onChange={handleChange}
                        >
                            {stateOptions.map((state) => (
                                <option key={state.value} value={state.value}>
                                    {state.value}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="profile-add-doctor-information-form-input-section">
                    <label>Zip code</label>
                    <input
                        type="text"
                        name="doctorZipCode"
                        placeholder="Zip Code"
                        value={form.doctorZipCode}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <ButtonWithLoading
                onClick={handleSave}
                loading={saveButtonIsLoading}
            >
                Save
            </ButtonWithLoading>
        </div >
    );
}

export default ProfileAddDoctorInformation;