import React, { createContext, useContext } from 'react';
import { useAuth } from './AuthContext';
import { useFirebase } from './FirebaseContext';

const ApiContext = createContext();

export const useApi = () => {
    return useContext(ApiContext);
};

export const ApiProvider = ({ baseURL, children }) => {
    const { sessionToken, logout, setSessionToken } = useAuth();
    const { auth } = useFirebase();

    const GET = 'get'
    const POST = 'post'
    const PUT = 'put'
    const DELETE = 'delete'

    function prepareHeaders() {
        let headers = new Headers()

        headers.append('Content-Type', 'application/json')
        headers.append('Accept', 'application/json')
        if (sessionToken) {
            console.log("sessionToken", sessionToken)
            headers.append('Authorization', `Bearer ${sessionToken}`);
        }

        return headers
    }

    async function fetchData(url, method, headers, body) {
        return await fetch(url, { method, headers, body })
            .then(async (data) => {
                if (data.status === 401) {
                    const retryResponse = await handleUnauthorizedRequest(url, method, headers, body);
                    return retryResponse;
                } else if (data.status === 200) {
                    return await data.json()
                } else if (data.status === 400) {
                    return await data.json()
                } else {
                    throw `Failed to fetch data with code: ${data.status}`
                }
            })
            .catch((e) => {
                if (!(e instanceof DOMException)) {
                    throw e.message
                }
            })
    }

    async function handleUnauthorizedRequest(url, method, headers, body) {
        const user = auth.currentUser;
        if (user) {
            try {
                const newToken = await user.getIdToken(true);
                setSessionToken(newToken)
                headers.set('Authorization', `Bearer ${newToken}`);
                const data = await fetch(url, { method, headers, body });

                if (data.ok) {
                    return await data.json();
                } else if (data.status === 400) {
                    return await data.json()
                } else {
                    logout(); // Logout the user if the second request fails
                    throw new Error(`Request failed with code: ${data.status}`);
                }
            } catch (error) {
                console.error('Token refresh failed:', error);
                logout(); // Logout if token refresh fails
                throw new Error('User needs to log in again.');
            }
        } else {
            logout();
            throw new Error('User needs to log in again.');
        }
    }

    async function fetchTask(status, taskId, cursor, limit) {
        if (baseURL) {
            let url = `${baseURL}/task`
            const queryParams = []
            if (status) {
                queryParams.push(`status=${status}`)
            }
            if (taskId) {
                queryParams.push(`task_id=${taskId}`)
            }
            if (cursor) {
                queryParams.push(`cursor=${cursor}`)
            }
            if (limit) {
                queryParams.push(`limit=${limit}`)
            }
            if (queryParams.length > 0) {
                url += `?${queryParams.join('&')}`
            }
            return await fetchData(url, GET, prepareHeaders(), null)
        }
    }

    async function fetchTaskWithCalls(taskId) {
        if (baseURL) {
            let url = `${baseURL}/task/calls`
            const queryParams = []
            if (taskId) {
                queryParams.push(`task_id=${taskId}`)
            }

            if (queryParams.length > 0) {
                url += `?${queryParams.join('&')}`
            }
            return await fetchData(url, GET, prepareHeaders(), null)
        }
    }

    async function addNewTask(title, call_prompt, outbound_phone_number) {
        if (baseURL) {
            const url = `${baseURL}/task/create`
            return await fetchData(url, POST, prepareHeaders(), JSON.stringify({
                title,
                call_prompt,
                outbound_phone_number
            }))
        }
    }

    async function generateTaskInputFields(task_id) {
        if (baseURL) {
            const url = `${baseURL}/task/input_fields`
            return await fetchData(url, POST, prepareHeaders(), JSON.stringify({
                task_id
            }))
        }
    }

    async function updateTask(task_id, taskData) {
        if (baseURL) {
            const url = `${baseURL}/task/update`
            return await fetchData(url, POST, prepareHeaders(), JSON.stringify({
                task_id,
                "update_data": taskData
            }))
        }
    }

    async function startTask(task_id, outbound_phone_number) {
        if (baseURL) {
            const url = `${baseURL}/task/start`
            return await fetchData(url, POST, prepareHeaders(), JSON.stringify({
                task_id,
                outbound_phone_number
            }))
        }
    }

    async function deleteTask(task_id) {
        if (baseURL) {
            const url = `${baseURL}/task`
            return await fetchData(url, DELETE, prepareHeaders(), JSON.stringify({
                task_id
            }))
        }
    }

    async function createTask(outbound_phone_number) {
        if (baseURL) {
            const url = `${baseURL}/task/create`
            return await fetchData(url, POST, prepareHeaders(), JSON.stringify({
                outbound_phone_number
            }));
        }
    }

    async function createEmptyTask() {
        if (baseURL) {
            const url = `${baseURL}/task/create/empty`
            return await fetchData(url, POST, prepareHeaders(), null);
        }
    }

    async function joinWaitlist(email) {
        if (baseURL) {
            const url = `${baseURL}/misc/waitlist`
            return await fetchData(url, POST, prepareHeaders(), JSON.stringify({
                email
            }));
        }
    }

    async function redeemInviteCode(invite_code) {
        if (baseURL) {
            const url = `${baseURL}/misc/redeem_invite_code`
            return await fetchData(url, POST, prepareHeaders(), JSON.stringify({
                invite_code
            }));
        }
    }

    async function updateUserProfile(update_data) {
        if (baseURL) {
            const url = `${baseURL}/user/update`
            return await fetchData(url, POST, prepareHeaders(), JSON.stringify({ update_data }));
        }
    }

    async function getUserProfile() {
        if (baseURL) {
            const url = `${baseURL}/user`;
            return await fetchData(url, GET, prepareHeaders(), null);
        }
    }

    const value = {
        fetchTask,
        addNewTask,
        updateTask,
        startTask,
        generateTaskInputFields,
        deleteTask,
        createTask,
        joinWaitlist,
        redeemInviteCode,
        createEmptyTask,
        updateUserProfile,
        getUserProfile,
        fetchTaskWithCalls
    }

    return (
        <ApiContext.Provider value={value}>
            {children}
        </ApiContext.Provider>
    );
};